// import ILL01 from '../../assets/images/global-banner/research.svg';
import ILL02 from '../../assets/images/global-banner/grades.svg';
// import ILL03 from '../../assets/images/global-banner/addForYouPage.svg';
import ILL04 from '../../assets/images/global-banner/updates.svg';
import ILL05 from '../../assets/images/global-banner/library.svg';
import ILL06 from '../../assets/images/global-banner/moveWidget.svg';

const GLOBAL_BANNERS = [
  // {
  //   title: 'Find your research related links from the app launcher',
  //   description: `Access myDevelopment and other valuable resource in just a few clicks`,
  //   backgroundColor: '#133053',
  //   // 17/7/2024
  //   startDate: 1721138400000,
  //   // 5/8/2024
  //   endDate: 1722780000000,
  //   theme: 'dark',
  //   illustration: ILL01,
  //   isNew: true,
  // },
  // {
  //   title: 'Add a default page (For you)',
  //   description: `You can now restore or add another default page by clicking on the page management options!`,
  //   backgroundColor: '#133053',
  //   // 8/8/2024 00:00:01
  //   startDate: 1723039201000,
  //   // 29/8/2024 23:59:59
  //   endDate: 1724939999000,
  //   theme: 'dark',
  //   illustration: ILL03,
  //   isNew: true,
  // },
  {
    title: 'Grades now available 👀',
    description: `If your assessment has been graded, you'll now see your unweighted grade in the assessment widget. And if you'd like to hide it from peeking eyes, you can!`,
    backgroundColor: '#133053',
    // Monday, 10 February 2025 11:30:00
    startDate: 1739147400000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL02,
    isNew: true,
  },
  {
    title: 'Stay in the loop',
    description: `With the new Updates page, you’ll get the reminders you need, when you need them – like when to re-enrol, pay your fees, or apply for a course transfer. And other key uni updates that are just good to know!`,
    backgroundColor: '#133053',
    // Thursday, 13 February 2025 11:00:00
    startDate: 1739404800000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL04,
    isNew: true,
  },
  {
    title: 'What’s new in the widget library',
    description: `The widget library has a new look! It’s now easier than ever to find what you need. There’s also a new widget to play with – make a quick link to your favourite site and customise it with background colours, images or GIFs.`,
    backgroundColor: '#133053',
    // Thursday, 13 February 2025 11:00:00
    startDate: 1739404800000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL05,
    isNew: true,
  },
  {
    title: 'Move widgets across custom pages',
    description: `Made the perfect custom widget but need it somewhere else? You can now move your widgets across pages – no need to start from scratch!`,
    backgroundColor: '#133053',
    // Thursday, 13 February 2025 11:00:00
    startDate: 1739404800000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL06,
    isNew: true,
  },
];

const COLLEGE_GLOBAL_BANNERS = [
  {
    title: 'Stay in the loop',
    description: `With the new Updates page, you’ll get the reminders you need, when you need them – like when to re-enrol, pay your fees, or apply for a course transfer. And other key uni updates that are just good to know!`,
    backgroundColor: '#133053',
    // Thursday, 13 February 2025 11:00:00
    startDate: 1739404800000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL04,
    isNew: true,
  },
  {
    title: 'What’s new in the widget library',
    description: `The widget library has a new look! It’s now easier than ever to find what you need. There’s also a new widget to play with – make a quick link to your favourite site and customise it with background colours, images or GIFs.`,
    backgroundColor: '#133053',
    // Thursday, 13 February 2025 11:00:00
    startDate: 1739404800000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL05,
    isNew: true,
  },
  {
    title: 'Move widgets across custom pages',
    description: `Made the perfect custom widget but need it somewhere else? You can now move your widgets across pages – no need to start from scratch!`,
    backgroundColor: '#133053',
    // Thursday, 13 February 2025 11:00:00
    startDate: 1739404800000,
    // Friday, 28 March 2025 23:59:00
    endDate: 1743166740000,
    theme: 'dark',
    illustration: ILL06,
    isNew: true,
  },
];

export const getGlobalBannerData = () => GLOBAL_BANNERS;
export const getGlobalBannerCollegeData = () => COLLEGE_GLOBAL_BANNERS;
